import React from 'react';
import './Footer.css'; // Opcional: estilos para el footer

function Footer() {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} TAQTICAL®️. Todos los derechos reservados.</p>
      <nav className="footer-nav">
        <a href="/privacidad">Política de privacidad</a>
        <a href="/terminos">Términos y condiciones</a>
        <a href="/contacto">Contacto</a>
      </nav>
    </footer>
  );
}

export default Footer;
