import React, { useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useTranslation } from 'react-i18next';

function Blog() {
  const { language } = useLanguage();
  const { t, i18n } = useTranslation('blog');

  useEffect(() => {
    i18n.changeLanguage(language.toLowerCase());
  }, [language, i18n]);

  return ( 
    <div className="main">
      <h1>{t('title')}</h1>
    </div>
  );
}

export default Blog;
