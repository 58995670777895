import React, { createContext, useState, useContext } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('ES');
  const [newLanguage, setNewLanguage] = useState('EN');

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'EN' ? 'ES' : 'EN'));
    setNewLanguage((prevLanguage) => (prevLanguage === 'EN' ? 'ES' : 'EN'));
  };

  return (
    <LanguageContext.Provider value={{ language, newLanguage, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);