import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { useTranslation } from 'react-i18next';
import './Header.css'; 

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { language, newLanguage, toggleLanguage } = useLanguage();
  const { t, i18n } = useTranslation('header');

  useEffect(() => {
    i18n.changeLanguage(language.toLowerCase());
  }, [language, i18n]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header">
      <nav className="navbar">
        <div className="logo">
          <Link to="/"><img src="/logo500.png" alt="Logo de Taqtical" className="logo-img" /></Link>
        </div>
        <ul className="nav-links">
          <li><Link to="/servicios">{t('services')}</Link></li>
          <li><Link to="/casos-de-exito">{t('success_cases')}</Link></li>
          <li><Link to="/blog">{t('blog')}</Link></li>
          <li><a href="#language" className="lang-switch" onClick={toggleLanguage}>🌐 {newLanguage}</a></li>
        </ul>
        <div className="hamburger-menu">
          <a href="#language" className="lang-switch" onClick={toggleLanguage}>🌐 {newLanguage}</a>
          &nbsp;
          <span onClick={toggleMenu}>
            &#9776; 
          </span>          
        </div>
        {menuOpen && (
          <div className="hamburger-menu-content">
            <ul>
              <li><Link to="/servicios" onClick={closeMenu}>{t('services')}</Link></li>
              <li><Link to="/casos-de-exito" onClick={closeMenu}>{t('success_cases')}</Link></li>
              <li><Link to="/blog" onClick={closeMenu}>{t('blog')}</Link></li>
            </ul>
          </div>        
        )}
      </nav>
    </header>    
  );
}

export default Header;
