// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    blog: {
      "title": "Blog"
    },
    header: {
      "home": "Home",
      "services": "Services",
      "success_cases": "Success Cases",
      "blog": "Blog"
    },
    home: {
      "title": "Welcome to Taqtical",
      "message": "Site is under construction. Please contact us at contactus@taqtical.co."
    },
    services: {
      "title": "Services"
    },
    success_cases: {
      "title": "Success Cases"
    },
  },
  es: {
    blog: {
      "title": "Blog"
    },
    header: {
      "home": "Inicio",
      "services": "Servicios",
      "success_cases": "Casos de éxito",
      "blog": "Blog"
    },
    home: {
      "title": "Bienvenidos a Taqtical",
      "message": "Sitio en renovación. Por favor contáctenos en contactenos@taqtical.co."
    },
    services: {
      "title": "Servicios"
    },
    success_cases: {
      "title": "Casos de éxito"
    },
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "es", 
    fallbackLng: "es",
    ns: ["blog", "header", "home", "services", "success_cases"],
    defaultNS: "header",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;